import StatusRequestNotTraitedException from "@/exceptions/status_request_not_traited_exception.js";
import TimeoutException from "@/exceptions/timeout_exception.js";
import TechnicalException from "@/exceptions/technical_exception.js";
import ResourceNotFoundException from "@/exceptions/resource_not_found_exception.js";
import UnauthorizedException from "@/exceptions/unauthorized_exception.js";
import AlreadyExistsException from "@/exceptions/already_exists_exception.js";
import ForbiddenException from "@/exceptions/forbidden_exception.js";
import IntegrityException from "@/exceptions/integrity_exception.js";

export class StatusConverter {
  constructor() {
    this.actions = [];

    this.add(401, (error) => {
      throw new UnauthorizedException(
        error,
        "une authentification est nécessaire pour accéder à la ressource."
      );
    });

    this.add(403, (error) => {
      try {
        if (error) {
          if (
            error.config.headers.Authorization &&
            error.config.headers["token-apm"]
          ) {
            console.error(
              "Le collaborateur n'a pas le droit (dgauth) d'accéder à cette ressource."
            );
          } else {
            console.error(
              "Un token est nécessaire pour accéder  à la ressource."
            );
          }
        }
      } catch {
        console.error(
          "Une authorisation est nécessaire pour accéder à la ressource."
        );
      }

      throw new ForbiddenException(
        error,
        "votre authentification n'est pas valide."
      );
    });

    // this.add(409, (error) => {
    //   throw new AlreadyExistsException(error, "cette entité existe déjà");
    // });
  }

  /**retourne true si le statut est géré par le convertisseur */
  hasStatus(status) {
    return !!this.actions.find((action) => action.status == status);
  }

  /**Ajouter une action en fonction d'un statut*/
  add(status, execute) {
    let action = this.actions.find((action) => action.status === status);
    if (action) {
      //l'action avec ce stattut est déjà définie dans la liste, on modifie le process
      action.execute = execute;
      return;
    }
    this.actions.push({
      status,
      execute,
    });
  }

  add404(resourceId) {
    this.add(404, (error) => {
      throw new ResourceNotFoundException(error, resourceId);
    });
  }

  /**Associer une IntegrityException avec le status 409 */
  add409Integrity(resourceId) {
    this.add(409, (error) => {
      throw new IntegrityException(error, resourceId);
    });
  }

  /**Associer une AlreadyExistsException avec le status 409 */
  add409AlreadyExists(resourceId) {
    this.add(409, (error) => {
      throw new AlreadyExistsException(error, resourceId);
    });
  }

  eatError(error) {
    if (error) {
      return true;
    } else {
      return false;
    }
  }

  convert(error) {
    if (error.response) {
      const status = error.response.status;

      //on recherche dans la configuration si il existe une gestion d'un statut particulier
      let action = this.actions.find((action) => action.status == status);

      if (action) {
        return action.execute(error);
      } else {
        //le statut de la requête n'est pas géré
        throw new StatusRequestNotTraitedException(error, status);
      }
    } else if (error.request) {
      //timeout en général
      throw new TimeoutException(error);

      //EVO gérer l'accès à une ressource inexistante, peut être utile dans le cas où l'application n'est plus à jour
      //(par exemple un problème de version ou de modification du service). On trouve également ce cas avec une mauvaise url vers ENDPOINT.
    } else {
      console.error(
        "problème lors du traitement de la requête (pas de réponse ni requête)."
      );

      //erreur pendant la construction de la requête`
      throw new TechnicalException(
        error,
        "problème lors du traitement de la requête"
      );
    }
  }
}
